import { useEffect, useState } from 'react';
import Close from '~/icons/Close';
import Heart from '~/icons/Heart';
import { getCookie, setCookie } from '~/utils/helpers';

import styles from './WelcomeMessage.module.scss';

const WelcomeMessage = () => {
  const [isMessageVisible, setIsMessageVisible] = useState(false);
  const cookieName = 'reader_welcome_message';

  useEffect(() => {
    const cookie = getCookie(cookieName);
    if (!cookie) {
      setIsMessageVisible(true);
    }
  }, []);

  const onButtonClick = () => {
    setCookie(cookieName, 'seen', 365);
    setIsMessageVisible(false);
  };

  if (!isMessageVisible) {
    return null;
  }

  return (
    <div className={styles.card}>
      <div className={styles.svgHolder}>
        <Heart />
      </div>
      <button onClick={onButtonClick} className={styles.button}>
        <Close />
      </button>
      <h3 className={styles.heading}>
        <span>Hey! Welcome to</span> <span>BP News:</span> Your Daily Dose of
        Trending News Highlights
      </h3>
      <p className={styles.text}>
        Your Daily Dose of Trending News Highlights. Stay up-to-date with BP
        News, summarizing top trending news into concise and engaging
        highlights. Discover the latest stories and insights every day.
      </p>
    </div>
  );
};

export default WelcomeMessage;
