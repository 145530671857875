const Heart = () => {
  return (
    <svg
      width='48'
      height='48'
      viewBox='0 0 48 48'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M0 8C0 3.58172 3.58172 0 8 0H40C44.4183 0 48 3.58172 48 8V40C48 44.4183 44.4183 48 40 48H8C3.58172 48 0 44.4183 0 40V8Z'
        fill='#42BE77'
        fillOpacity='0.4'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M11.896 24.5951C10.4654 20.1284 12.1387 14.5751 16.828 13.0657C19.2947 12.2697 22.3387 12.9337 24.068 15.3191C25.6987 12.8457 28.8307 12.2751 31.2947 13.0657C35.9827 14.5751 37.6654 20.1284 36.236 24.5951C34.0094 31.6751 26.24 35.3631 24.068 35.3631C21.8974 35.3631 14.1974 31.7577 11.896 24.5951Z'
        stroke='#42BE77'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M29.0518 18.0853C30.6611 18.2507 31.6678 19.5267 31.6078 21.3147'
        stroke='#42BE77'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};

export default Heart;
